import { HtmlHead } from '@hanwhalife/reactjs/components/_shared';
import { metaMessage } from '@hanwhalife/constants';
import { PageNotFound } from '@hanwhalife/reactjs/components/cold-case/PageNotFound';
import { useHlpRouter } from '@hanwhalife/reactjs/router';
import { useLoginInfo } from '@hanwhalife/shared-store';
import { useCallback } from 'react';

// Good to know: In addition to catching expected notFound() errors, the root app/not-found.js file also handles any unmatched URLs for your whole application. This means users that visit a URL that is not handled by your app will be shown the UI exported by the app/not-found.js file.
export default function NotFound() {
  const router = useHlpRouter();
  const {
    loginInfo: { isLogined, customerYn }
  } = useLoginInfo();

  const goToMain = useCallback(() => {
    // 로그인
    if (isLogined) {
      customerYn === 'Y' ? router.moveMainTabPage('0') : router.moveMainTabPage('1');
    }

    // 비로그인
    if (!isLogined) {
      router.moveMainTabPage('1');
    }
  }, [isLogined, customerYn]);

  return (
    <>
      {/* 봇 접근 막기 */}
      <HtmlHead title={metaMessage.title} isBotDisabled={true} />
      <PageNotFound onMoveRootPage={goToMain} />
    </>
  );
}
