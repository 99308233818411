import { MouseEvent } from 'react';
import Image from 'next/image';
import { useHlpRouter } from '@hanwhalife/reactjs/router';
import { useTheme } from '@hanwhalife/shared-libs/emotion/react';
import { Button, Feedback, IconColorProblem, TextCaption, TextTitle } from '@hanwhalife/shared-libs/design-system';
import { StyledBottomBanner, StyledError } from '@hanwhalife/styled/_shared';
import { HLI_CDN_STATIC } from '@hanwhalife/constants';

export const PageNotFound = ({ onMoveRootPage }: { onMoveRootPage?: () => void }) => {
  const theme = useTheme();
  const router = useHlpRouter();

  const handleRootPage = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    router.push('/');
  };

  return (
    <>
      <StyledError>
        <Feedback
          icon={() => <IconColorProblem width="64" height="64" aria-hidden="true" />}
          title={<div className="typography-title3">페이지를 찾을 수 없어요</div>}
          bodyContents={
            <p className="typography-body2 text-body3">
              찾으시는 주소가 잘못 입력되었거나 변경 혹은 삭제되어 주소를 사용할 수 없어요. <br />
              입력하신 페이지의 주소를 다시 한번 확인해주세요.
            </p>
          }
          className="fill-feedback text-center"
        />

        <StyledBottomBanner as="a" href="tel: 1544-6385">
          <div className="text-box">
            <TextTitle level="title3" overlineText="고객센터로 문의하기" overlineColorText={theme.colorText_body_1}>
              1544-6385
            </TextTitle>
            <TextCaption>평일 오전 9시 ~ 오후 6시</TextCaption>
          </div>
          <div className="image-box">
            <Image
              src={`${HLI_CDN_STATIC}/hlp/micro/images/illust/cs.svg`}
              alt=""
              width={100}
              height={117}
              className="banner-img"
            />
          </div>
        </StyledBottomBanner>

        <Button tagName="a" href="/" size="large" block onClick={onMoveRootPage ?? handleRootPage} className="mt-8">
          홈으로 가기
        </Button>
      </StyledError>
    </>
  );
};
